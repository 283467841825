<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5104 13C10.2894 13 10.0775 12.9123 9.92124 12.7563L6.5879 9.42981C6.50831 9.3531 6.44483 9.26133 6.40115 9.15987C6.35748 9.05841 6.33449 8.94928 6.33353 8.83886C6.33257 8.72843 6.35365 8.61892 6.39555 8.51672C6.43745 8.41452 6.49933 8.32166 6.57757 8.24358C6.65582 8.1655 6.74886 8.10374 6.85127 8.06193C6.95369 8.02011 7.06342 7.99907 7.17407 8.00003C7.28472 8.00099 7.39407 8.02393 7.49574 8.06752C7.59741 8.1111 7.68936 8.17446 7.76624 8.25389L10.5104 10.9924L13.2546 8.25389C13.4117 8.1024 13.6222 8.01858 13.8407 8.02047C14.0592 8.02237 14.2682 8.10983 14.4228 8.26402C14.5773 8.41821 14.6649 8.62679 14.6668 8.84484C14.6687 9.06289 14.5847 9.27296 14.4329 9.42981L11.0996 12.7563C10.9433 12.9123 10.7314 13 10.5104 13Z"
      fill="currentColor"
    />
  </svg>
</template>
